import { Box, Typography } from '@mui/material';
import React from 'react';
import { pdfjs } from 'react-pdf';

import { DocumentDiffChange, DocumentDiffChangeMap } from '../../types/RealtaDocument';
import { getDiffColor } from './utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

type DiffViewProps = {
  diffs?: DocumentDiffChangeMap;
  showDetails?: boolean;
  onDiffClick?: (diff: DocumentDiffChange) => void;
};

const DiffView: React.FC<DiffViewProps> = ({ diffs, showDetails = true, onDiffClick }) => {
  return (
    <Box p={1}>
      {diffs?.values() &&
        Array.from(diffs.values())
          .sort((a, b) => {
            return a.joinFullId.localeCompare(b.joinFullId);
          })
          .sort((a, b) => a.startBoundryPage - b.startBoundryPage)
          .map((diff, index) => (
            <Box
              mb={2}
              key={index}
              borderRadius={2}
              bgcolor={getDiffColor(diff.type)}
              py={1}
              px={2}
              onClick={() => {
                if (onDiffClick) {
                  onDiffClick(diff);
                }
              }}
              sx={{ cursor: 'pointer' }}
            >
              {showDetails && (
                <Typography fontSize={12} fontWeight="bold" textTransform="capitalize">
                  ParHash: {diff.parHash}
                </Typography>
              )}
              {showDetails && (
                <Typography fontSize={12} fontWeight="bold" textTransform="capitalize">
                  sectionHash: {diff.sectionHash}
                </Typography>
              )}
              {showDetails && (
                <Typography fontSize={12} fontWeight="bold" textTransform="capitalize">
                  sectionId: {diff.uuid}
                </Typography>
              )}
              <Typography fontSize={12} fontWeight="bold" textTransform="capitalize">
                {diff.type}
              </Typography>
              <Typography fontSize={12}>Content: {diff.content}</Typography>
              <Typography fontSize={12}>FullIds: {JSON.stringify(diff.fullIds)}</Typography>
              <Typography fontSize={12}>Join FullId: {diff.joinFullId}</Typography>
              <Typography fontSize={11}>Start page: {diff.startBoundryPage}</Typography>
              <Typography fontSize={11}>End page: {diff.startBoundryPage}</Typography>
            </Box>
          ))}
    </Box>
  );
};
export default DiffView;
