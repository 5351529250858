// @ts-nocheck
import { Router } from '@gatsbyjs/reach-router';
import React from 'react';

import CompareDocument from '../components/CompareDocument/CompareDocument';
import AppLayout from '../components/layout/AppLayout';

const Compare = () => {
  return (
    <AppLayout>
      <Router>
        <CompareDocument path="/document-comparison" />
      </Router>
    </AppLayout>
  );
};
export default Compare;
